// extracted by mini-css-extract-plugin
export var share = "single-post-module--share--3u9Yk";
export var component = "single-post-module--component--2QrLv";
export var header = "single-post-module--header--1moY6";
export var back = "single-post-module--back--30PJ6";
export var info = "single-post-module--info--1Gq5y";
export var main = "single-post-module--main--_jyV3";
export var content = "single-post-module--content--2MB3b";
export var category = "single-post-module--category--2XRC_";
export var title = "single-post-module--title--2sADw";
export var videoContainer = "single-post-module--videoContainer--1oQ-Q";
export var video = "single-post-module--video--25OBb";