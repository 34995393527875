import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import { getPostUrl } from "components/utils"

import Title from "components/elements/title"
import Hr from "components/elements/hr"
import Content from "components/elements/content"
import PostCategory from "components/blog/post-category"
import PostImage from "components/blog/post-image"
import PostInfo from "components/blog/post-info"
import Svg from "components/svgs/svg"

import * as s from "./single-post.module.sass"

const SinglePost = (props) => {
  const { post, markdownContent, videoContent, iframeContent } = props;
  const {
    title,
    slug,
    category,
    author,
    publishedDate,
    featuredImage,
    content,
  } = post;
  const filteredMarkdownContent = markdownContent.replace(
	/]\(\/uploads/g,
	`](${process.env.API_URL}/uploads`)
  const postUrl = process.env.SITE_URL + getPostUrl(slug);
  const shareTitle = title;

  return (
    <div className={s.component}>
      <div className="container">
        <p className={s.back}>
          <Link to={`${process.env.SITE_URL}/blog`}>← Back to blog</Link>
        </p>

        <header className={s.header}>
          <PostCategory parentClass={s.category} category={category} />

          <Title parentClass={s.title} level="1">
            {title}
          </Title>

          <PostInfo
            parentClass={s.info}
            author={author}
            publishedDate={publishedDate}
          />

          <Hr parentClass={s.hr} />
        </header>

        {/* Render the featured image */}
        <PostImage image={featuredImage} fullsize />

        <main className={s.main}>
          <aside className={s.side}>
            <div className={s.share}>
              <p>Share</p>

              <EmailShareButton url={postUrl} subject={shareTitle}>
                <Svg id="email" />
              </EmailShareButton>

              <TwitterShareButton
                url={postUrl}
                via="NetspeakGames"
                title={shareTitle}
              >
                <Svg id="twitter" />
              </TwitterShareButton>

              <FacebookShareButton url={postUrl} quote={shareTitle}>
                <Svg id="facebook" />
              </FacebookShareButton>

              <LinkedinShareButton
                url={postUrl}
                title={shareTitle}
                source="Netspeak Games"
              >
                <Svg id="linkedin" />
              </LinkedinShareButton>
            </div>
          </aside>

          <Content parentClass={s.content}>
            {/* Render the Markdown content */}
            <ReactMarkdown parentClass={s.content}>{filteredMarkdownContent}</ReactMarkdown>
			
			 {/* Render multiple iframe contents */}
          {iframeContent && iframeContent.map((iframeHtml, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: iframeHtml }}
            />
          ))}

            {/* Render the Video content */}
			<div className={s.videoContainer}>
            {videoContent.map((videoHtml, index) => (
              <div
                key={index}
                className={s.video}
                dangerouslySetInnerHTML={{ __html: videoHtml }}
              />
            ))}
			</div>
			
		  
          </Content>
        </main>
      </div>
    </div>
  )
}

export default SinglePost
