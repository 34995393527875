import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "layouts/default"
import Header from "components/components/header"
import SinglePost from "components/blog/single-post"
import OtherPosts from "components/blog/other-posts"

export const query = graphql`
  query PostQuery($postSlug: String!, $categorySlug: String) {
    strapi {
      postBySlug(slug: $postSlug) {
        ...postFields
        content
      }
      posts(limit: 2, where: { isPublished: true, slug_ne: $postSlug, category: { slug: $categorySlug } }) {
        ...postFields
      }
    }
  }
`

// Function to process content with shortcodes and extract videos
const processContentWithShortcodes = (content) => {
	
	// Extract iframe elements
  const iframeRegex = /<iframe[^>]*>.*?<\/iframe>/g;
  const iframeMatches = content.match(iframeRegex);
  const iframeContent = iframeMatches ? iframeMatches : [];

  // Remove iframes from the content
  content = content.replace(iframeRegex, "");
  
  // Define a regular expression to match the custom shortcode pattern, e.g., {{youtube VIDEO_ID}}
  const shortcodeRegex = /{{youtube\s([\w-]+)}}/g;

  // Process the content and replace custom shortcodes with embedded YouTube videos
  const processedContent = content.replace(shortcodeRegex, (match, videoId) => {
    // Generate the HTML code for embedding the YouTube video using the videoId
    return `<div class="video-container" style="position: relative; padding-top: 56.25%;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe></div>`;
  });

  // Define a regular expression to match the video container divs
  const videoContainerRegex = /<div class="video-container"[^>]*>.*?<\/div>/g;

  // Extract video elements from the processed content using regex
  const videoMatches = processedContent.match(videoContainerRegex);
  const videoContent = videoMatches ? videoMatches : [];

  // Remove the video-container divs from the processed content
  const markdownContent = processedContent.replace(videoContainerRegex, "");

  return { markdownContent, videoContent, iframeContent };
};

  
const BlogPostTemplate = (props) => {
  const { data } = props
  const post = data.strapi.postBySlug
  const posts = data.strapi.posts

  // Process the content to replace custom shortcodes with embedded videos
	const { markdownContent, videoContent, iframeContent } = processContentWithShortcodes(post.content)

  return (
    <Layout>
      <Helmet>
        <title>Netspeak Games Blog</title>
      </Helmet>
      <Header />
      <SinglePost post={post} markdownContent={markdownContent} videoContent={videoContent} iframeContent={iframeContent} />
      <OtherPosts posts={posts} />
    </Layout>
  )
}

export default BlogPostTemplate
