import React from "react"
import { Link } from "gatsby"

import { useBlogPosts } from "src/hooks/use-blog-posts"
import Post from "components/blog/post"
import Title from "components/elements/title"
import Hr from "components/elements/hr"

import * as s from "./other-posts.module.sass"

const Posts = (props) => {
  const { posts } = props
  const allPosts = posts || useBlogPosts()

  if (!allPosts || allPosts.length < 1) {
    return null;
  }

  return (
    <div className={s.component}>
      <div className="container">
        <header className={s.header}>
          <Title parentClass={s.title}>More to read</Title>

          <p className={s.all}>
            <Link to={`${process.env.SITE_URL}/blog`}>All posts →</Link>
          </p>
        </header>

        <Hr parentClass={s.hr} isLeft />

        <div className={s.posts}>
          {allPosts.map((post) => (
            <Post key={post.slug} post={post} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Posts
